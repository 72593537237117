/*Font*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=PT+Mono&display=swap");

:root {
  --black: #000000;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray-light: #ddd;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --lightest: #FAFAFA;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --blue: #4ba6df;
  --gray: #8b8a87;
  --primary: #4ba6df;
  --primary-hover: #2d9adf;
  --secondary: #595f72;
  --secondary-hover: #4c5161;
  --success: #28a745;
  --success-hover: #08a745;
  --info: #abc4bf;
  --info-hover: #8ea29e;
  --warning: #eeb868;
  --warning-hover: #d4a45d;
  --light: #eeeeef;
  --font-family-sans-serif: "Open Sans", sans-serif;
  --font-family-monospace: "PT Mono", monospace;
}

body {
  font-family: var(--font-family-sans-serif);
}

/* Sub heading text color */
h5.sub {
  font-weight: 300;
  color: var(--gray);
  font-family: var(--font-family-monospace);
  font-size: 0.9rem;
}

/*Headings*/
h1,
h2,
h3,
h4,
h5 {
  font-weight: 800;
  /* text-transform: lowercase; */
}

button {
  text-transform: lowercase;
}

.btn-group-lg > .btn,
.btn-lg {
  border-radius: 0;
}

.btn {
  border-radius: 0;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 50px;
}

.forest {
  width: 100%;
  height: auto;
}

/* Primary button/badge color */
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover {
  /* adding fallbacks for hover to be primary color if not defined */
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.btn-group-lg > .btn,
.btn-lg {
  font-size: 1.1rem;
  padding: 0.5rem 2.5rem;
}

.badge-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

/*Secondary button/badge color*/
.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
}

.badge-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

/*Light button/badge color*/
.btn-light {
  background-color: var(--light);
  border-color: var(--light);
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background-color: var(--light);
  border-color: var(--light);
}

.btn-light:hover {
  background-color: var(--light-hover);
  border-color: var(--light-hover);
}

.badge-light {
  background-color: var(--light);
  border-color: var(--light);
}

.badge-light {
  background-color: var(--light);
  border-color: var(--light);
}

.btn-nav {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding-top: 0.25rem;
}
.btn-nav.backBtn {
  padding-left: 0.5rem;
}
.btn-nav.nextBtn {
  padding-left: 0.9rem;
}

/*Info Button/badge color*/
.btn-info {
  background-color: var(--info);
  border-color: var(--info);
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: var(--info);
  border-color: var(--info);
}

.btn-info:hover {
  background-color: var(--info-hover);
  border-color: var(--info-hover);
}

.badge-info {
  background-color: var(--info);
  border-color: var(--info);
}

/*Warning Button/badge color*/
.btn-warning {
  background-color: var(--warning);
  border-color: var(--warning);
  color: var(--white)!important;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:hover {
  background-color: var(--warning-hover);
  border-color: var(--warning-hover);
  color: var(--white);
}

.badge-warning {
  background-color: var(--warning);
  border-color: var(--warning);
}

/*Success Button/badge color*/
.btn-success {
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: var(--success);
  border-color: var(--success);
}

.btn-success:hover {
  background-color: var(--success-hover);
  border-color: var(--success-hover);
}

.badge-success {
  background-color: var(--success);
  border-color: var(--success);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--blue);
  border-color: var(--blue);
}

/* Links */

a {
  color: var(--secondary);
}

a:hover {
  color: var(--gray-dark);
}

/*Color Areas*/
.grayBack {
  background-color: var(--light);
}

.colorBar {
  padding: 3rem 3rem;
  text-align: center;
}

.colorBar h1 {
  font-size: 3rem;
  color: var(--white);
}

.colorBar h2 {
  font-size: 1.4rem;
}

/*Image Banner*/
.imageBanner {
  padding: 3rem 3rem;
  /*text-align: center;*/
}

.imageBanner h1 {
  font-size: 3rem;
  color: var(--white);
}

.imageBanner h2 {
  font-size: 1.4rem;
}

.imageBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*Text*/
.text-blue {
  color: var(--blue);
}

/*Background Colors*/
.primaryColor {
  background-color: var(--primary);
}
.secondaryColor {
  background-color: var(--secondary);
}

.lightColor {
  background-color: var(--light);
}

.infoColor {
  background-color: var(--info);
}

.warningColor {
  background-color: var(--warning);
}

.successColor {
  background-color: var(--success);
}
.tranBlack {
  background-color: rgba(0, 0, 0, 0.8);
}

/*Menu*/
.divider:before,
.divider:after {
  content: "\f107";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: var(--white);
  font-weight: 300 !important;
  pointer-events: none;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.divider[aria-expanded="true"]:after,
.divider[aria-expanded="true"]:before {
  top: 30%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.divider[aria-expanded="false"]:after,
.divider[aria-expanded="false"]:before {
  top: 30%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*Accordions*/
.panel-title a {
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.panel-title a .caret {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-title a.collapsed .caret {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.accordion.custom-accordion .card,
.accordion.custom-accordion .card-header {
  border-radius: 0;
  background: var(--white);
  box-shadow: none;
}
.accordion.custom-accordion .panel-title,
.accordion.custom-accordion .card-header {
  border-bottom: none;
}
.accordion.custom-accordion .panel-title {
  padding: 10px;
  position: relative;
}
.accordion .card-body {
  padding: 0 2.45rem 1.25rem;
}
.accordion.custom-accordion .panel-title > a {
  text-decoration: none;
  width: 100%;
  display: block;
  font-weight: bolder;
  /* color: var(--blue); */
}
.accordion.custom-accordion .panel-title:before,
.accordion.custom-accordion .panel-title:after {
  content: "\f107";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: var(--gray);
  font-weight: 300 !important;
  pointer-events: none;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.accordion.custom-accordion .panel-title:before {
  width: auto;
  font-family: inherit;
  color: var(--light);
  content: "Expand";
  -webkit-transform: translate(-10%, -50%);
  transform: translate(-10%, -50%);
  text-align: right;
  right: 50px;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.accordion.custom-accordion .panel-title:hover:before {
  opacity: 0.66;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.accordion.custom-accordion .active-panel .panel-title:after {
  /*content: "\f106";*/
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg);
}

.accordion.custom-accordion .active-panel .panel-title:before {
  content: "Collapse";
}
.accordion.custom-accordion .card .panel-body {
  padding: 35px !important;
  padding-top: 0 !important;
  border-top: none !important;
  /*font-family: "Helam Slab", georgia, serif;*/
}
.accordion.custom-accordion .card {
  position: relative;
  overflow: hidden;
}
.accordion.custom-accordion .card:before {
  transition-delay: 300ms !important;
  position: absolute;
  content: "";
  width: 4px;
  height: 100%;
  top: 0;
  left: -15px;
  background: var(--orange);
  display: block;
  overflow: visible;
  -webkit-transition: all 500ms ease !important;
  transition: all 500ms ease !important;
}
.accordion.custom-accordion .active-panel:before {
  left: 0;
}
.accordion.custom-accordion .card + .card {
  margin-top: -1px !important;
}

/* Circle Buttons */
.btn-circle.btn-sm { 
  width: 30px; 
  height: 30px; 
  padding: 6px 0px; 
  border-radius: 15px; 
  font-size: 8px; 
  text-align: center; 
} 
.btn-circle.btn-md { 
  width: 45px; 
  height: 45px; 
  padding: 7px 10px; 
  border-radius: 25px; 
  font-size: 10px; 
  text-align: center; 
} 
.btn-circle.btn-xl { 
  width: 70px; 
  height: 70px; 
  padding: 10px 16px; 
  border-radius: 35px; 
  font-size: 12px; 
  text-align: center; 
} 

.btn-circle:hover path{
  fill: #fff;
}

/* Circle Person */
.circlePerson h5, .circlePerson p{
  margin-bottom: 0;
}

.circlePerson{
  border-radius: 25px;
  border: none;
}

/* Card 1 */
.card-custom {
  overflow: hidden;
  min-height: 450px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
}

.card-custom-img {
  height: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}

/* First border-left-width setting is a fallback */
.card-custom-img::after {
  position: absolute;
  content: '';
  top: 161px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  top: 100px;
  left: 1.25rem;
  width: 100px;
  height: 100px;
}

/* Quote */
.blockquote-custom {
  position: relative;
  font-size: 1.1rem;
}

.blockquote-custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: 50px;
}

/*Modal*/
.close {
  font-weight: 300;
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  border-radius: 0;
}

/*Size classes*/
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.h-10 {
  width: 10%;
}

.h-20 {
  width: 20%;
}

.h-30 {
  width: 30%;
}

.h-40 {
  width: 40%;
}

.h-60 {
  width: 60%;
}

.h-70 {
  width: 70%;
}

.h-80 {
  width: 80%;
}

.h-90 {
  width: 90%;
}

/*Menu Tray*/
.menuTray {
  z-index: 10001;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  top: 0;
  height: 100%;
  width: 90%;
  max-width: 500px;
  background-color: var(--gray-dark);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  overflow-y: auto;
  padding-bottom: 25px;
  transition: all 500ms ease-in-out;
}
.menuBackdrop {
  pointer-events: none;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 500ms ease-in-out;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
}
.menuTitle {
  padding-left: 45px;
}

.menuShowing .menuBackdrop {
  pointer-events: all;
  opacity: 1;
}
.menuShowing .menuTray {
  transform: translateX(0);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3), 0 0 12px 0 rgba(0, 0, 0, 0.25);
}
@media (hover: none) {
  .menuShowing {
    overflow: hidden;
  }
}

.menuTray p {
  font-size: 13px;
  color: var(--light);
}

.menuTray h5 {
  color: var(--white);
  font-weight: 700;
}

.menuTray .list-group-item-action {
  background-color: var(--gray-dark);
  color: var(--light);
}

.menuTray .list-group-item-action:hover {
  background-color: var(--dark);
  color: var(--light);
}

.divider {
  font-weight: bold;
}

.list-group-item-dark {
  color: var(--white);
  background-color: var(--dark);
}

.menuTray .list-group a {
  color: var(--light);
  transition: all 200ms ease;
  background: rgba(0, 0, 0, 0.2);
  padding-left: 45px;
  position: relative;
}
.menuTray .list-group a::after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.05);
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.menuTray .list-group a.menuActive::after,
.menuTray .list-group a.menuVisited::after {
  background-color: rgba(0, 0, 0, 0.3);
  background-size: 50% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/%3E%3C/svg%3E");
}
.menuTray .list-group a.menuActive {
  font-weight: bold;
}
.menuTray .list-group a.menuVisited::after {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.6;
}
.menuTray .list-group a.menuActive.menuVisited::after {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.menuTray .list-group > div > a {
  background: rgba(0, 0, 0, 0.05);
}
.menuTray .list-group > div > a::after {
  background: transparent;
}

.menuTray .list-group a:hover {
  text-decoration: none;
  color: var(--light);
  background: rgba(0, 0, 0, 0.33);
}

.menuTray .container {
  padding: 0;
}

.menu-close {
  background:none!important;
}

.menuIcon {
  width: 40px;
  height: 35px;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 999;
  border:none;
  background: repeating-linear-gradient(
    0deg,
    #000,
    #000 5px,
    transparent 5px,
    transparent 15px
  );
}
.menuIcon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.menu-close:before,.menu-close:after{
    content:'';
    position:absolute;
    width:36px;
    height:2px;
    background-color:white;
    border-radius:0;
    top:16px;
}

.menu-close:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left:2px;
}
.menu-close:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right:2px;
}

.close {
  max-height: 30px;
  max-height: auto;
  cursor: pointer;
}

.check {
  width: 20px;
  height: 20px;
  display: none;
}

/*Next button*/
.full-next-page {
  background-color: var(--light);
  padding: 25px;
  border-top: 1px solid var(--gray);
}

.full-next-page:hover {
  background-color: var(--light-next);
  cursor: pointer;
}

.full-next-page h5 {
  margin: 0;
  color: var(--gray);
}

.full-next-page p {
  margin: 0;
  color: var(--gray);
  font-size: 0.8rem;
}

.fa-chevron-down {
  color: var(--gray-dark);
}

.rounded-edges {
  border-radius: 25px;
}

.circleBtn {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.backBtn {
  position: fixed;
  left: 15px;
  bottom: 15px;
  z-index: 998;
}

.nextBtn {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 998;
}

/* Quotes */
.quote1Img {
  width: 80px;
  height: 80px;
}

.quote2Img {
  width: 120px;
  height: 120px;
}

.quote3Img {
  width: 140px;
  height: 140px;
}

.quote1 > p,
.quote2 > .flex-column > p,
.quote3 > .flex-column > p {
  font-weight: 100;
  margin: 0;
}

.quote1 > p:last-child,
.quote2 > .flex-column > p:last-child,
.quote3 > .flex-column > p:last-child {
  font-weight: 900;
  color: slategrey;
}

.quote3 {
  font-size: 1.2rem;
}

/*Tabs*/
.nav-pills .nav-link {
  border-radius: 0;
  border: 0.8px solid rgba(0, 0, 0, 0.2);
}

#pills-tabContent {
  border-radius: 0;
  border-left: 0.8px solid rgba(0, 0, 0, 0.2);
  border-right: 0.8px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.2);
}

#v-pills-tabContent {
  border-radius: 0;
  border: 0.8px solid rgba(0, 0, 0, 0.2);
}

/*Circle Numbered Lists*/
.circle-numbered-list{
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-left: 60px;
  padding-right: 60px;
}

.circle-numbered-list > li{
  padding-bottom: 15px;
  margin-bottom: 15px;
  counter-increment: my-awesome-counter;
  position: relative;
}

.circle-numbered-list > li:before{
  content: counter(my-awesome-counter) "";
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  --size: 50px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: var(--primary);
  border-radius: 50%;
  text-align: center;
}

/*Squared Numbered Lists*/
.square-numbered-list{
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-left: 60px;
  padding-right: 60px;
}

.square-numbered-list > li{
  padding-bottom: 15px;
  margin-bottom: 15px;
  counter-increment: my-awesome-counter;
  position: relative;
}

.square-numbered-list > li:before{
  content: counter(my-awesome-counter) "";
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  --size: 50px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: var(--success);
  border-radius: 10%;
  text-align: center;
}

/*Circle Unordered Lists*/
.circle-unordered-list{
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-left: 60px;
  padding-right: 60px;
}

.circle-unordered-list > li{
  padding-bottom: 15px;
  margin-bottom: 15px;
  counter-increment: my-awesome-counter;
  position: relative;
}

.circle-unordered-list > li:before{
  content: "";
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  --size: 30px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: var(--primary);
  border-radius: 50%;
  text-align: center;
}

/*Question Text*/
.questionText {
  font-size: 2rem;
  font-weight: 100;
}

.questionText span {
  font-weight: 700;
}

.questionHeader {
  margin-bottom: 0 !important;
}


.ld-multiple-select {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  margin-top: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ld-multiple-select input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ld-multiple-select .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform:translateY(-50%);
  height: 25px;
  width: 25px;
  background-color: var(--gray-light);
  transition: all 200ms ease;
}

/* On mouse-over, add a grey background color */
.ld-multiple-select:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ld-multiple-select input:checked ~ .checkmark {
  background-color: var(--primary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.ld-multiple-select .checkmark:after {
  content: "";
  position: absolute;
  display:block;
  opacity:0;
  transition:all 200ms ease;
  transform: rotate(45deg) translate(-50%,-50%) scale(0);
}

/* Show the checkmark when checked */
.ld-multiple-select input:checked ~ .checkmark:after {
  opacity:1;
  transform: rotate(45deg) translate(-50%,-50%) scale(1);
}

/* Style the checkmark/indicator */
.ld-multiple-select .checkmark:after {
  top: 45%;
  left: 25%;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
}


/* Customize the label (the ld-multiple-choice) */
.ld-multiple-choice {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  margin-top: 6px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.ld-multiple-choice input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.ld-multiple-choice .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform:translateY(-50%);
  height: 25px;
  width: 25px;
  background-color: var(--gray-light);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.ld-multiple-choice:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.ld-multiple-choice input:checked ~ .checkmark {
  background-color: var(--primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.ld-multiple-choice .checkmark:after {
  content: "";
  position: absolute;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition: all 200ms ease;
  display: block;
}

/* Style the indicator (dot/circle) */
.ld-multiple-choice .checkmark:after {
  top:50%;
  left:50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 0 white, 0 0 0 0 var(--gray-light);
}

/* Show the indicator (dot/circle) when checked */
.ld-multiple-choice input:checked ~ .checkmark:after {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--primary);
}

.ld-multiple-choice input:focus ~ .checkmark:after,
.ld-multiple-choice:hover .checkmark:after {
  box-shadow: 0 0 0 3px white, 0 0 0 5px var(--gray-dark);
}

.ld-multiple-select input:focus ~ .checkmark,
.ld-multiple-select:hover ~ .checkmark {
  box-shadow: 0 0 0 1px white, 0 0 0 3px var(--gray-dark);
}

/*Pop Over*/
.popover {
  border-radius: 0;
}

#correctFeedback .modal-header {
  background-color: var(--success);
  color: var(--white);
}

#incorrectFeedback .modal-header {
  background-color: var(--danger);
  color: var(--white);
}

.modal-dialog {
  margin: 4.5rem 0.5rem;
}

/*Video*/
video {
  width: 100%;
  height: auto;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  /*Color Bar*/
  .colorBar {
    padding: 6rem 10rem;
  }
  .colorBar h1 {
    font-size: 4rem;
  }
  .colorBar h2 {
    font-size: 2rem;
  }
  /*Image Banner*/
  .imageBanner {
    padding: 6rem 10rem;
  }
  .imageBanner h1 {
    font-size: 4rem;
  }
  .imageBanner h2 {
    font-size: 2rem;
  }
  /*Next/Back*/
  .circleBtn {
    width: 50px;
    height: 50px;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

.disabled,
:disabled {
  pointer-events:none!important;
  opacity:0.25!important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-black {
  background: #000;
}

.embed-wrapper {
  position: relative;
  padding: 0;
  margin: 0;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
  margin-left: -15px;
  margin-right: -15px;
}
.embed-wrapper  iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}
